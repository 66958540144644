<template>
  <vx-card
    :title="title"
    title-color="success"
  >
    <div>
      <div class="vx-row mb-12">
        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Scrap ID</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="code"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        
        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>External Code</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="externalCode"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="selectedWarehouse"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Request Date</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <template>
                  <datepicker name="date" :inline="false" v-model="date" placeholder="Select Date" :required="true" :disabled="true"></datepicker>
              </template>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <template>
                    <datepicker name="posting_date" :inline="false" v-model="posting_date" placeholder="Select Date" :disabled="true"></datepicker>
                </template>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Scrap Type</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="selectedScrapType"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        <!-- <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  class="w-full"
                  v-model="selectedSupplier"
                  :disabled="true"
                />
            </div>
          </div>
        </div> -->

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Cost Center</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-input
                  class="w-full"
                  v-model="selectedCostCenter"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Charge To</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-input
                  class="w-full"
                  v-model="selectedCharge"
                  :disabled="true"
                />
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <span>Note</span>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-textarea v-model="note"  class="w-full" v-validate="'required'" :disabled="true"/>
              <span class="text-danger text-sm" v-show="errors.has('Note')">{{
                errors.first("Note")
              }}</span>
            </div>
          </div>
        </div>

        <div class="vx-col w-1/2">
          <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/5 w-full">
              <label
                >Attachment
                <div class="vx-row ml-2 mb-10">
                  <small style="color: red"
                    >(only: jpg, jpeg, pdf, png, video)</small
                  >
                </div>
              </label>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <div class="vx-col w-1/2">
                <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                <input
                  id="fileInput"
                  name="file"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".jpg, .jpeg, .png, .pdf, .mp4, .avi, .mkv, .3gp, .m4v, .wmv, .mov"
                />
              </div>
            </div>
            <div class="vx-col sm:w-4/5 w-full">
              <vs-button
                class="mr-3 mb-2"
                type="relief"
                icon-pack="feather"
                icon="icon-plus"
                size="small"
                @click="handleAttachment"
                >Attachment</vs-button
              >
            </div>
          </div>
        </div>
        
        <!-- display: block -->
        <vs-divider style="width: 50%; margin-left: 10%">
          List Attachment
        </vs-divider>
        <div
          class="vx-row mb-3 mt-6 w-4/4"
          style="width: 50%; margin-left: 10%"
        >
          <table class="vs-table vs-table--tbody-table">
            <template>
              <tr
                class="tr-values vs-table--tr tr-table-state-null selected"
                v-bind:key="tr.NameFile"
                v-for="(tr, i) in fileAttachment"
              >
                <td class="td vs-table--td">{{ tr.NameFile ? tr.NameFile : tr.FileName }}</td>
                <td class="td vs-table--td">
                  <vx-tooltip text="Show" v-if="tr.Path != ''">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click.stop="downloadFileAwsS3(tr.Path)"
                    />
                  </vx-tooltip>
                </td>

                <td class="td vs-table--td">
                  <template>
                    <vx-tooltip text="Delete">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click.stop="handleDeleteAttachment(i)"
                      />
                    </vx-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    
      <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      
        <div class="vx-col sm:w-5/5 w-full mb-2">
          <div class="vx-row mb-2">
            <div class="vx-col w-4/12">
              <label class="vs-input--label">Special Area</label>
            </div>
            <div class="vx-col w-4/12">
              <label class="vs-input--label">SKU</label>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">Batch</label>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">Expired Date</label>
            </div>
          </div>
        </div>

        <div class="vx-row mb-2">
          
          <div class="vx-col w-4/12">
              <vs-input
                class="w-full"
                :disabled="true"
                v-model="items[indextr].warehouseArea.selected"
              />
            </div>

            <div class="vx-col w-4/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].sku.selected"
                :disabled="true"
              />
            </div>

            <div class="vx-col w-2/12">
              <vs-input
                class="w-full"
                :disabled="true"
                v-model="items[indextr].batch"
              />
            </div>

            <div class="vx-col w-2/12">
              <datepicker name="expired_date" 
                :disabled="true" :inline="false" v-model="items[indextr].expiredDate" placeholder="Select Date"></datepicker>
            </div>

        </div>

        <div class="vx-col sm:w-5/5 w-full mb-2">
          <div class="vx-row mb-2">
            <div class="vx-col w-1/12">
              <label class="vs-input--label">Available Qty (UOM)</label>
            </div>
            <div class="vx-col w-1/12">
              <label class="vs-input--label">UOM</label>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">MAP</label>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">Handling Unit</label>
            </div>
            <div class="vx-col w-1/12">
              <label class="vs-input--label">Qty</label>
            </div>
            <div class="vx-col w-2/12">
              <label class="vs-input--label">Amount</label>
            </div>
            <div class="vx-col w-3/12">
              <label class="vs-input--label">Reason</label>
            </div>
          </div>
        </div>
        <div class="vx-row mb-2">
            <div class="vx-col w-1/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].qtyAvailable"
                v-validate="'required'"
                :disabled="true"
              />
            </div>
            <div class="vx-col w-1/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].uom"
                v-validate="'required'"
                :disabled="true"
              />
            </div>
            
            <div class="vx-col w-2/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].map"
                v-validate="'required'"
                :disabled="true"
              />
            </div>
            
            <div class="vx-col w-2/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].handlingUnit.selected"
                :disabled="true"
              />
            </div>

            <div class="vx-col w-1/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].qty"
                v-validate="'required'"
                :disabled="true"
              />
            </div>
            
            <div class="vx-col w-2/12">
              <vs-input
                class="w-full"
                v-model="items[indextr].amount"
                :disabled="true"
              />
            </div>

            <div class="vx-col w-3/12">
              
              <vs-input
                class="w-full"
                :disabled="true"
                v-model="items[indextr].reason.selected"
              />
            </div>
        </div>
        
        <vs-divider></vs-divider>
    </div>

        <div class="vx-col sm:w-5/5 w-full mb-2">
          <div class="vx-row mb-2">
            <div class="vx-col w-4/12">
              <vs-button
                class="mb-2"
                color="success"
                icon-pack="feather"
                type="relief"
                icon="icon-check"
                @click="SubmitForm()"
              >
                Attach
              </vs-button>
            </div>            
            <div class="vx-col w-2/12 text-right">
              Total Amount :
            </div>
            <div class="vx-col w-2/12">
              <div class="vx-col w-12/12">
                <vs-input
                  class="w-full"
                  v-model="totalAmount"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="vx-col w-2/12">
              
            </div>
            <div class="vx-col w-2/12">
              <vs-button
                class="mb-2"
                icon-pack="feather"
                color="primary"
                type="border"
                icon="icon-x"
                @click="handleClose()"
                >Close</vs-button
              >
            </div>
          </div>
        </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      title: this.$route.query.id ? "Stock Scrap - View" : "Stock Scrap - Create",
      editOrShow: false,
      code: null,
      externalCode: null,
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      table: this.tableDefaultState(),
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      posting_date: moment().format("YYYY-MM-DD"),
      file_attachments: 2,
      optionItemSKU: [],
      selectItemSKU: [null],
      optionWarehouseArea: [],
      optionReason: [],
      optionWarehouse: [],
      selectedWarehouse: null,
      optionScrapType: [],
      selectedScrapType: null,
      optionSupplier: [],
      selectedSupplier: null,
      optionCostCenter: [],
      selectedCostCenter: null,
      optionChartOfAccount: [],
      selectedChartOfAccount: null,
      optionCharge: ["Internal", "Supplier"],
      selectedCharge: "Internal",
      note: null,
      itemID: [],
      unitID: [],
      warehouseAreaID: [],
      qtyArray: [],
      batchArray: [],
      expiredDateArray: [],
      reasonID: [],
      totalAmount:0,
      items: [{
            status: "plus",
            sku:{
                selected: null,
                options: [],
            },
            handlingUnit: {
                selected: null,
                options: [],
            },
            warehouseArea: {
                selected: null,
                options: [],
            },
            reason: {
                selected: null,
                options: [],
            },
            qtyAvailable: null,
            uom: null,
            map: null,
            qty: 0,
            batch:null,
            amount: 0,
            expiredDate: moment().format("YYYY-MM-DD"),
        }],
      fileAttachment: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleClose() {
      // back to list
      this.$vs.loading();
      if(this.$route.query.is_approval == 1){
        this.$router.push({ 
          name: "stock-scrap-approval", 
          query: { tab: this.$route.query.tab }, 
        });
      }else{
        this.$router.push({ 
          name: "stock-scrap", 
          query: { tab: this.$route.query.tab } 
        });
      }
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.code = resp.data.scrap.Code;
            this.externalCode = resp.data.scrap.ExternalCode;
            this.date = resp.data.scrap.Date;
            this.posting_date = resp.data.scrap.PostingDate;
            this.selectedCharge = resp.data.scrap.Charge;
            this.note = resp.data.scrap.Note;
            this.selectedWarehouse = resp.data.scrap.WarehouseCode + " - " +  resp.data.scrap.WarehouseName
            this.selectedScrapType = resp.data.scrap.Type
            this.selectedSupplier = resp.data.scrap.SupplierCode + " - " +  resp.data.scrap.SupplierName
            this.selectedCostCenter = resp.data.scrap.CostCenterCode + " - " +  resp.data.scrap.CostCenterName
            this.selectedChartOfAccount = resp.data.scrap.ChartOfAccountCode + " - " +  resp.data.scrap.ChartOfAccountName
            
            this.items = [];
            var statusAdd;
            var totalAmount1 = 0;
            for(var k = 0; k < resp.data.scrap_line.length; k++){
              _this.items.push({
                  status: statusAdd,
                  sku:{
                      selected: resp.data.scrap_line[k].ItemCode + " - " + resp.data.scrap_line[k].ItemName,
                      options: [],
                  },
                  handlingUnit: {
                      selected: resp.data.scrap_line[k].ItemUnit,
                      options: [],
                  },
                  warehouseArea: {
                      selected: resp.data.scrap_line[k].StorageCode + " - " + resp.data.scrap_line[k].StorageName,
                      options: [],
                  },
                  reason: {
                      selected: resp.data.scrap_line[k].Reason,
                      options: [],
                  },
                  qtyAvailable: resp.data.scrap_line[k].QuantityAvailable,
                  uom: resp.data.scrap_line[k].ItemUom,
                  map: _this.formatMoney(resp.data.scrap_line[k].Value, 7, ".", ","),
                  amount : _this.formatMoney(resp.data.scrap_line[k].Value * resp.data.scrap_line[k].Quantity, 7, ".", ","),
                  qty: resp.data.scrap_line[k].Quantity,
                  batch: resp.data.scrap_line[k].ItemBatch,
                  expiredDate: resp.data.scrap_line[k].ItemExpiredDate,
              })

              totalAmount1 = totalAmount1 + (resp.data.scrap_line[k].Value * resp.data.scrap_line[k].Quantity);
            }

            this.fileAttachment = resp.data.scrap_attachment;
            this.totalAmount = this.formatMoney(totalAmount1, 7, ".", ",")

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        
        if (result) {
          this.putData();        
        }
      });
    },
    putData() {
      
      let form = new FormData();
      var attachment_id
      for (let index = 0; index < this.fileAttachment.length; index++) {
        if(typeof this.fileAttachment[index].id === "undefined"){
          attachment_id = 0
        }else{
          attachment_id = this.fileAttachment[index].id
        }
        form.append("attachment_id[]", attachment_id);
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/scrap/attach/" + this.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Scrap has been attach",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getItems();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getItems();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getItems();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getItems();
    },
    handleSearchItemSKU(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getItems();
    },
    mappingAttachment() {
      this.adjustment.Attachment.map((v) => {
        this.fileAttachment.push({
          ListID: v.ListID,
          NameFile: v.NameFile,
          PathFile: v.PathFile,
        });
      });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    formatMoney(val, decimal, decSep, thouSep) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(decimal).replace(thouSep, decSep);
      var valsplit = val.split(".")
      return valsplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"."+valsplit[1];
    },
  },

  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
